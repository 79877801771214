<template>
  <el-dialog
    id="BonusMatrixHistoryDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="700px"
    top="5vh"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @keydown.enter.native="onFormEnter"
    destroy-on-close
  >
    <el-container v-loading="loading">
      <div ref="flex_container" class="flex_container">
        <ag-grid-vue
          ref="bonusMatrixHistoryTable"
          id="bonusMatrixHistoryTable"
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          rowSelection="single"
          :defaultColDef="defaultColDef"
          :columnDefs="columns"
          :headerHeight="32"
          :rowData="history"
          :gridOptions="gridOptions"
          @selection-changed="onGridSelectionChanged"
          :overlayNoRowsTemplate="noRowsToShowTemplate"
          @gridReady="onGridReady"
          @sortChanged="onGridColumnsChange"
          @columnResized="onGridColumnsChange"
          @columnMoved="onGridColumnsChange"
          @displayedColumnsChanged="onGridColumnsChange"
          :modules="modules"
          :localeTextFunc="gridLocale"
          :enableBrowserTooltips="true"
          :sideBar="sideBar"
          :pivotMode="false"
          :getContextMenuItems="contextMenuItems"
        >
        </ag-grid-vue>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Закрити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import Icon from "@/components/IconPack";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

export default {
  name: "BonusMatrixHistory",
  components: { AgGridVue, Icon },
  props: ["busEvent"],
  data() {
    var that = this;
    return {
      loading: false,
      modules: AllModules,
      dialogShow: false,
      dialogHeight: 0,
      resizeObserver: null,
      title: $tt("Пошук"),
      target: null,
      model: {},
      initModel: {},
      filterString: "",
      selectedRows: null,
      selectedParentRules: [],
      waitForAnswer: false,
      keyUpTimer: null,
      filterHasChanged: false,
      noRowsToShowTemplate: `<div><img src="${require("../../assets/icons/no_data.svg")}"></div>`,
      gridOptions: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data._id;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },

      columns: [
        {
          headerName: $tt("Значення бонусу"),
          field: "coeff",
          flex: 1,
          type: "rightAligned",
          filter: "agSetColumnFilter",
        },
        {
          headerName: $tt("Дата введення"),
          field: "created.timestamp",
          flex: 1,
          sort: "desc",
          valueGetter: (params) => {
            return (
              new Date(params.data.created.timestamp).toLocaleDateString("ru-RU") + " " + new Date(params.data.created.timestamp).toLocaleTimeString("ru-RU")
            );
          },
          filter: "agDateColumnFilter",
        },
        {
          headerName: $tt("Користувач"),
          field: "created.userId",
          flex: 2,
          filter: "agSetColumnFilter",
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
    };
  },
  mounted() {
    //window.addEventListener("keydown", this.onKeyDownHandler);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeyDownHandler);
  },
  created() {
    var that = this;
    bus.$on(this.busEvent + ".show", (data) => {
      if (!that.dialogShow) {
        that.title = (data && data.title) || that.title;
        that.initModel = _.extend({}, data.initModel ? data.initModel : {});
        that.loading = true;
        that.dialogShow = true;
      }
    });
    bus.$on(this.busEvent + ".hide", () => {
      that.dialogShow = false;
      that.resizeObserver = null;
    });
  },
  computed: {
    history() {
      return this.$store.getters["analytics/bonusMatrixHistory"]();
    },
  },
  watch: {
    dialogHeight(value) {
      var containerHeight = value - 48 - 48 - 32;
      // 48 - header 72 - footer 48 - find input 20 - padding 16 - 2 header
      this.$refs.flex_container.style.height = `${containerHeight}px`;
    },
  },
  methods: {
    onGridReady() {
      this.restoreGridSettings(this, this.gridOptions, "bonusMatrixHistory", this.columns, $tt("Історія змін"));
      window.addEventListener("keydown", this.onKeyDownHandler);
    },
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
        });
        this.resizeObserver.observe(this.$el.children[0]);
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
      this.resizeObserver = null;
      this.dialogHeight = 0;
    },
    onOpen() {
      this.$store.dispatch("analytics/clearBonusMatrixHistory");
      this.observeHeight();
    },
    onClose() {
      this.unobserveHeight();
    },
    onOpened() {
      var that = this;
      this.model = _.extend({}, this.initModel);
      this.$store
        .dispatch("analytics/getBonusMatrixHistory", { contractId: this.model.contractId, axiomaId: this.model.axiomaId })
        .then(() => {})
        .catch((err) => {
          globalErrorMessage($tt("Помилка"), err);
        })
        .finally(() => {
          that.loading = false;
        });
    },
    onCommit() {
      this.dialogShow = false;
    },
    onGridSelectionChanged() {
      var rows = this.gridOptions.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedParentRules = rows.map((row) => row._id);
      } else {
        this.selectedParentRules = null;
      }
    },
    onFormEnter() {},
    onGridColumnsChange() {
      this.changeColumnsTimer = setTimeout(() => {
        clearTimeout(this.changeColumnsTimer);
        this.saveGridState(this.gridOptions, "bonusMatrixHistory");
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
    getContextIcon(icon) {
      return `<img class="svg" src="${require(`../../assets/icons/${icon}.svg`)}" />`;
    },
    contextMenuItems(params) {
      var contextMenu = [];
      var that = this;
      contextMenu.push(
        {
          name: $tt("Фільтр для кожного стовпчика"),
          shortcut: "Ctrl + F",
          icon: that.getContextIcon("apply_for_column_green"),
          action() {
            that.toggleFloatingFilter(that.gridOptions, that.columns);
          },
        },
        "separator"
      );
      contextMenu.push("export");
      return contextMenu;
    },
    onKeyDownHandler(e) {
      if (e.ctrlKey) {
        if (e.key.toLowerCase() == "f" || e.key.toLowerCase() == "а") {
          this.toggleFloatingFilter(this.gridOptions, this.columns);
          e.preventDefault();
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scope>
#BonusMatrixHistoryDialog .header2 {
  background: #fff;
  line-height: 32px;
  border-bottom: 1px solid #ccc !important;
  padding: 0 6px !important;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

#BonusMatrixHistoryDialog.my-dialog .el-dialog--center .el-dialog__body {
  padding: 0px 16px 0 16px !important;
}
</style>
